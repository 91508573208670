<template>
	<div>
		<div class="veadmin-iframe-header veadmin-iframe-header-custom-fixed">
			<span>当前位置:</span>
			首页设置<span>&gt;</span>
			轮播图管理
			<button class="veadmin-iframe-button" onclick="location.reload()">
				<i class="el-icon-refresh-right"></i>
			</button>
		</div>
		<div class="veadmin-iframe-content veadmin-iframe-content-custom-fixed">
			<el-row style="margin:10px;">
				<el-button type="primary" icon="el-icon-plus" @click="addCarousel()">增加轮播图</el-button>
			</el-row>
			<el-row class="el-row">
				<el-card style="margin:10px;" shadow="hover" v-for="(carousel, count) in carouselList" :key="count">
					<img :src="apiServer + carousel.image_url" class="image" />
					<div style="padding: 14px;">
						<h3>{{ carousel.title }}</h3>
						<p>{{ carousel.text }}</p>
						<p>跳转链接：{{ carousel.target_url }}</p>
						<p>打开新窗口：<el-switch v-model="carousel.new_page" disabled> </el-switch></p>
						<div class="bottom clearfix">
							<!-- <el-button circle disabled icon="el-icon-top" @click="editCarousel(item.id)"></el-button>
							<el-button circle icon="el-icon-bottom" @click="editCarousel(item.id)"></el-button> -->
							<el-button type="primary" icon="el-icon-edit" circle @click="editCarousel(carousel.id)"></el-button>
							<el-button type="danger" icon="el-icon-delete" circle @click="deleteCarousel(carousel.id)"></el-button>
						</div>
					</div>
				</el-card>
			</el-row>
		</div>
	</div>
</template>

<script>
import { apiListCarouselByCondition, apiDeleteCarousel } from "@/api/admin/carousel.js";
import base from "@/base.js";

export default {
	data() {
		return {
			condition: {
				status: ""
			},
			apiServer: base.apiServer,
			carouselList: []
		};
	},
	methods: {
		// 获取激活的轮播图
		async listCarousel() {
			try {
				let formData = new FormData();
				formData.append("status", this.condition.status);
				/* for (let [a, b] of formData.entries()) {
					console.log(a + ":", b || '""');
				} */
				const response = await apiListCarouselByCondition(this.$store.state.token, formData);
				//console.log(response);
				if (response.data.code == 200) {
					this.carouselList = response.data.data;
				} else {
					console.log("response>>", response);
					this.$message.error(response.data.message);
				}
			} catch (error) {
				console.log(error);
			}
		},
		addCarousel() {
			this.$router.push({ path: "/pc/admin/carousel/add" });
		},
		editCarousel(id) {
			window.location.href = "/pc/admin/carousel/edit?id=" + id;
		},
		// 删除轮播图
		async deleteCarousel(id) {
			try {
				let formData = new FormData();
				formData.append("id", id);
				/* for (let [a, b] of formData.entries()) {
					console.log(a + ":", b || '""');
				} */
				const response = await apiDeleteCarousel(this.$store.state.token, formData);
				//console.log(response);
				if (response.data.code == 200) {
					this.$message({ message: "删除成功！", type: "success" });
					//执行成功1秒后刷新页面
					setTimeout(function() {
						location.reload();
					}, 1000);
				} else if (response.data.code == -100) {
					this.$confirm(response.data.message + "。将跳转至登录页面, 是否继续?", "提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "error"
					})
						.then(() => {
							//跳转到登录页面（路由跳转则滑动验证会失效）
							//this.$router.push({ path: "/login?target=" + window.location.href });
							window.location.href = "/login?target=" + window.location.href;
						})
						.catch(() => {
							this.$message({
								type: "warning",
								message: "已取消"
							});
						});
				} else {
					console.log("response>>", response);
					this.$message.error(response.data.message);
				}
			} catch (error) {
				console.log(error);
			}
		}
	},
	mounted() {
		//页面渲染完成调用方法获取数据
		this.listCarousel();
	}
};
</script>

<style lang="scss" scoped>
.el-row {
	max-width: 1140px;
}
.image {
	max-width: 1080px;
	max-height: 100%;
	/* 	width: 1080px;
	height: 270px; */
}
</style>
